@import 'colors';
@import 'sizes';
@import 'Progressbar';
@import 'Header';
@import 'Diagram';
@import 'Footer';

html, body{
  background: $dark_blue;
  margin: 0;
  height: 100%;
  overflow: hidden;
  position: relative;
}
html{
z-index: -3;
position: fixed;
z-index: -1;
width: 100%;
    overflow: hidden;
        height: 100%;
}
a {
  color: $orange;
}

.App {
  text-align: center;
  background-color: $blue;
}
.node {
  cursor: move;
  transition: r 0.2s;

}
.node:after {
  content: '';
  transition: r 0.2s;

}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}


body {
  overflow: hidden;

}
@media only screen and (max-width: $mobile) {

  h1{
    font-size: 18px;
    margin-bottom: 4px;
    margin-top: 0px;
  }
  #header {
    padding: 20px;
  }
  .overflow {
    overflow: hidden;
    padding: 5px 0 5px 0;
  }
  .progress{
    height: 4px;
  }

  .progress .bar{
    height: 4px;
    border-radius: 2px;
  }
}
