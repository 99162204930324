#mysvg {
  z-index: 100;
  position: fixed;
  left: 0;
  span{
    height: 100vh;
  }
  text {
    fill: $orange;
    font-family: monospace;
    font-size: 20px;
  }

  circle {
    cursor: move;
    transition: r 0.2s;
    z-index: 1000;
  }

  text {
    cursor: default;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    tspan {
      font-size: 16px;
      fill: rgba(255, 255, 255, 0.6);
    }
  }
}
@media only screen and (max-width: $mobile) {
  #mysvg text {
    fill: #E65100;
    font-family: monospace;
    font-size: 16px;
    tspan {
          font-size: 14px;
              fill: $transparent-white;
    }
  }
}
