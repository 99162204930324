#header {
  position: fixed;
  padding: 40px;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 10;
  span{
    color: $orange;
    font-family: monospace;
    font-size: 16px;
  }
  h1{
    color: $transparent-white;
    font-family: monospace;
    font-weight: 300;
    font-size: 24px;
    margin-bottom: 4px;
    margin-top: 0px;
  }
}

@media only screen and (max-width: $mobile) {
  #header {
    h1 {
      font-size: 18px;
      margin-bottom: 4px;
      margin-top: 0px;
    }
  }
}
