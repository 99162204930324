.madeby{
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-family: monospace;
  color: rgba(255, 255, 255, 0.6);
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 100;
}
