$progressbarsheight: 6px;
$progressbarsradius: 3px;

$pointheight: 10px;
$pointheighthover: 12px;

.progressbars{
  width: 100%;
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
  max-width: 550px;
  bottom: 0;
  padding: 40px;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  .left{
    float: left;
  }
  .right{
    float: right;
  }
  label{
    color: $transparent-white;
    font-family: monospace;
  }
  .labels{
    overflow: hidden;
    padding: 15px 0 5px 0;
        padding: 15px 0 8px 0;
            font-size: 14px;
  }
}

.progress{
  width: 100%;
  height: $progressbarsheight;
  border-radius: $progressbarsradius;
  background-color: #032059;
  .bar{
    width: 1%;
    height: $progressbarsheight;
    position: relative;
    border-radius: $progressbarsradius;
    background-color: $orange;
  }
  .point {
    right: 0;
    top: 0;
    position: absolute;
    height: 13px;
    width: 13px;
    z-index: 150;
    cursor: pointer;
    margin-right: -5px;
    margin-top: -4px;
    &:after{
      content: '';
      height: 12px;
      width: 12px;
      position: absolute;
      border-radius: 50%;
      background-color: #ffffff;
      z-index: -1;
      top: 1px;
      left: 1px;
    }
  }
}

@media only screen and (max-width: $mobile) {
  .progressbars{
    padding: 20px;
    padding-bottom: 50px;
    .labels {
      padding: 8px 0 8px 0;
      font-size: 12px;
    }
  }
  .progress {
    .point{
      height: 35px;
      width: 35px;
      margin-right: -15px;
      margin-top: -16px;
      z-index: 150;
      cursor: pointer;
      &:after {
        content: '';
        height: 12px;
        width: 12px;
        position: absolute;
        border-radius: 50%;
        background-color: #ffffff;
        z-index: -1;
        top: 12px;
        left: 11px;
      }
    }
  }
}
/* smartphones, touchscreens */
@media (hover: none) and (pointer: coarse) {
      /* ... */
  .progressbars{
    .labels {
      padding: 8px 0 8px 0;
      font-size: 12px;
    }
  }
  .progress {
    .point{
      height: 35px;
      width: 35px;
      margin-right: -15px;
      margin-top: -16px;
      z-index: 150;
      cursor: pointer;
      &:after {
        content: '';
        height: 12px;
        width: 12px;
        position: absolute;
        border-radius: 50%;
        background-color: #ffffff;
        z-index: -1;
        top: 12px;
        left: 11px;
      }
    }
  }
}
